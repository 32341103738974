import React from "react";
import ProdLayout from "../../prodlayout";
import pi1 from "./p1.jpg";
import pi2 from "./p2.jpg";
import pi3 from "./p3.jpg";
import pi4 from "./p4.jpg";
const data = {
  images: [pi1, pi2, pi3, pi4],
  badge: "New",
  sku: "114",
  availability: "In stock",
  title: "OCSO Stylish Jacket UK114",
  mrpPrice: "2420",
  sellPrice: "1290",
  disc:
    "OCSO Men's Active Jacket offers the best of performance, style and comfort. Designed to fit your perfectly, this jacket allows free movement while keeping you protected from the cold",
};
export default () => {
  return <ProdLayout data={data} />;
};
